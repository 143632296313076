.icons {
  margin-top: 100px;

  &-border {
    margin-top: 100px;
    border: 2px solid $color-main;
    padding: 50px 40px 40px;
  
    h4 {
        font-weight: 300;
        margin-left: 44.3%;
        margin-bottom: 130px;
    }
  }

  &-set {
    display: grid;
    text-align: center;
    gap: 1vw;
  
    svg {
      margin-bottom: 20px;
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;
      max-width: 200px;
      justify-self: center;
  
      &::before {
        content: none;
      }
  
    }
  
    &__item-text {
        font-weight: 500;
        font-size: rem(14px);
        color: #6c4f2d;
    }
  
    @include mq($until: xxl) {
      &__item-text {
        font-size: rem(13px);
      }
    }
  
    @include mq($until: xl) {
      &__item-text {
        font-size: rem(14px);
      }
    }
  
    @include mq($until: lg) {
      &-border {
        margin-top: 50px;
      }
    }
  
    @include mq($until: md) {

      &__item-text {
        font-size: 1rem;
      }

      svg {
        width: 60px;
        height: auto;
      }
    }
  
  }

  @include mq($until: md) {
    margin-top: 20px;
    &-border {
      margin-top: 0;
      padding: 30px 15px 15px;

      h4 {
        text-align: center;
        margin-left: 0;
        margin-bottom: 50px;
      }
    }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq($until: xl) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include mq($until: md) {

    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
      max-width: 90%;
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}