.home {
    position: relative;
}

.bg {
    background-image: url(../../images/bg-image.jpg);
    background-repeat: no-repeat;
    width: 100%;
    height: 250vh;
    position: absolute;
    top: calc(100vh - 4vw);
    z-index: -1;
    background-size: 100%;

    &-2 {
        top: unset;
        bottom: 100vh;
    }
}
