.footer {
  height: 100vh;
  width: 100%;
  background-size: cover;
  margin-top: 150px;
  display: flex;
  align-items: flex-end;

  &__content {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background: #006909a1 0% 0% no-repeat padding-box;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
  }

  &__contacts { 
    margin-left: 30px;

    svg {
      fill: white;
      height: 16px;
      width: auto;
      margin-right: 7px;


    }
    .fb {
      svg {
        height: 24px;

        &:hover {
          fill: $color-main;
        }
      }
    }

    * {
      color: white;
    }
  }

  &__logo {
    width: 100px;
    object-fit: contain;
  }

  &__form {
    width: 50%;
    position: relative;
    z-index: 5;
    background: transparent linear-gradient(180deg, #FBFBFBA8 0%, #FEFEFE 44%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    height: fit-content;
    padding: 40px;
    border-radius: 10px;
    position: absolute;
    bottom: 75px;
    z-index: 5;
    right: 8vw;

    &_top {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      margin-bottom: 35px;
    }
  }


  @include mq($until: xxl) {
      &__form {
          width: 47%;
      }
  }

  @include mq($until: xl) {

      &__form {
          width: 60%;
          right: 65px;
      }
  }

  @include mq($until: lg) {
      &__form {
          width: calc(100% - 350px);
          right: 50px;
      }

      &__content {
        flex-direction: column;
      }

      &__contacts {
        margin-left: 0;
        margin-top: 20px;
      }

  }

  @include mq($until: md) {

      &__form {
          width: calc(100% - 330px);
          padding: 25px;
      }
  }

  @include mq($until: sm) {
    min-height: 800px;
    &__form {
        right: 30px;
        width: calc(100% - 60px);
        bottom: 360px;
    }

    &__contacts {
      * {
        text-align: center;
      }
    }
      
    &__content {
      padding-top: 80px;

      img {
        width: 50px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  @include mq($until: xs) {
    width: 100%;
    &__form {
        padding: 25px 15px;
        right: 15px;
        width: calc(100% - 30px);
        &_top {
          grid-template-columns: repeat(1, 1fr);
        }
    }
  }

  @include mq($until: 500) {
    min-height: 920px;
    &__form {
      // bottom: 170px;
    }
  }
}

.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;

    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}

.fakebox {
  width: 43px;
  height: 43px;
  border: 1px solid #C1BBA9;
  display: block;
  transition: all .3s;
  border-radius: 6px;
  margin-right: 10px;

  @include mq($until: sm) {
    width: 39px;
    height: 39px;
  }
}

.fakebox + span {
  width: 80%;

  @include mq($until: xs) {
    width: 80%;
  }
}