.flat {

	&__card {
		display: flex;
		border: 1px solid $color-main;
		height: 100%;
		justify-content: space-between;

		img {
			height: 100%;
		}
	}

	&__info {
		padding: 30px 0 30px 30px;

		h2 {
			color: $color-main;
			font-size: rem(18px);
			text-align: left;
			border-bottom: 1px solid gray;
			padding-bottom: 15px;
		}
	}

	&__button {
		padding: 10px 15px;
		border: none;
		color: white;
		display: flex;
		justify-content: space-between;
	
		svg {
			width: 20px;
			height: auto;
			fill: white;
		}

		&_mail {
			background-color: $color-main;
			width: 60%;
			text-align: left;
		}

		&_pdf {
			background-color: #707070;
			width: 37%;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}
	
	&__area {
		border-bottom: 1px solid gray;
    padding: 0 15px 15px 15px;
	}

	&__rooms {
    padding: 0 15px;
    li {
      display: flex;
      justify-content: space-between;
    }
	}

	&__img {
		border-left: 1px solid $color-main;

		&-zoom {
			height: auto;
			display: flex;
			position: absolute;
			// bottom: 15px;
			bottom: 5px;
			right: 10px;
			text-decoration: underline;

      svg {
        width: 15px;
        margin-left: 10px;
        height: auto;
      }
		}

		a {
			display: flex;
			height: 100%;
			flex-direction: column;
			position: relative;
		}
	}

	@include mq($until: 1400) {
		&__img {
			width: 350px;
		}

		&__info {
			padding: 60px 0;
			margin: 0 auto;
		}
	}

	@include mq($until: 700) {
		&__img {
			width: calc(100% - 300px);

			img {
				object-fit: cover;
			}
		}
	}
	@include mq($until: 600) {
		&__img {
			display: none;
		}

		&__info {
			padding: 30px;
			width: 350px;
		}
	}
	@include mq($until: 450) {

		&__info {
			padding: 15px;
			width: 100%;
		}
	}
}