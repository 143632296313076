.widget {
	width: 100%;
	position: relative;
	// overflow: hidden;
    margin-top: 100px;

	&__rotate {
		position: relative;
		// height: 42vw;
	}

	.off {
		opacity: .5;
		pointer-events: none;
	}

	&__tip-info {
		display: flex;
		justify-content: space-between;
	}
	
	&_back {
		display: none;
	}

    svg {
        height: 100%;
        width: 100%;
    }

	h2, h3 {
		text-align: center;
	}
	
	video {
		width: 100%;
		height: auto;
		display: none;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.rotate {
		position: absolute;
		right: 2vw;
		z-index: 10;
		top: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
  
	.rotate__title {
		font-size: 1.125rem;
		font-weight: 600;
		color: $color-extra;
	}
	
	.rotate div {
		display: flex;
		margin-right: 20px;
	}
	
	.rotate span {
		width: 54px;
		margin: 0 5px;
	}
	
	.rotate span svg {
		cursor: pointer;
	}
	
	.rotate span .arrow-bg {
		fill: $color-main;
		transition: fill .4s;
	}
	
	.rotate span .arrow-arrow {
		fill: white;
	}
	
	.rotate span svg:hover .arrow-bg {
		fill: $color-extra;
	}
	
	svg {
		width: 100%;
		height: 100%;
	}

	&__floor {
		opacity: 0;
		transition: all .3s;
		cursor: pointer;

		&:hover {
			opacity: 0.5;
		}
	}

	* {
		font-size: rem(15px);
	}

	&__total {
		font-size: 60px;
		font-weight: 600;
		line-height: 1.2;
	}

	&__response {
		height: 25vw;
		min-height: 520px;
		display: flex;
	}

	&__house {
		border-radius: 50%;
		width: 200px;
		height: 200px;
		position: absolute;
		z-index: 5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		top: 80px;

		&-a {
			left: 0;
		}

		&-b {
			right: 0;
		}

	}

	&__floor-title {
		position: absolute;
		color: $color-extra;
		top: 50px;
		left: 60px;
		text-transform: uppercase;
	}

	&__panel {
		width: 100%;
		padding-top: 40px;
		padding-bottom: 40px;
		position: absolute;
		bottom: 0;
		left: 0%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		select {
			margin-right: 10px;
			background-color: transparent;
			background-image: url('../../images/arrow.png');
			background-repeat: no-repeat;
			background-position: 92% 42%;
			background-size: 13px;
			// background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
			-moz-appearance: none; 
			-webkit-appearance: none; 
			appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.486);
			border-radius: 5px;
			padding: 10px 35px 10px 15px;
			font-weight: 300;
			font-family: $font-family;
			transition: all .4s;

			&:active, &:focus {
				border: 1px solid rgb(255, 255, 255);
			}
		}

		option {
			color: black;
		}

		.button {
			padding: 15px 30px;
			margin-left: 7px;

			&.active {
				color: #53575E;
				background-color: white;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.85);
				color: #23262b;
			}
		}
	}
	&__ajax-floor {

		width: calc(100% - 700px);
		height: 100%;
		background-color: white;
		z-index: 10;
		position: relative;

		&.active {
			left: 0;
		}

	}

	&__ajax-flat {
		width: 700px;
		background-color: white;
		z-index: 10;
		position: relative;
	}

	&-sub {
		// margin-top: ;
	}

	@include mq($until: xl) {
		&__panel {
			width: 100%;
			padding-top: 40px;
			padding-bottom: 40px;
			position: absolute;
			bottom: 0;
			left: 0%;
			display: flex;
			justify-content: space-between;
			align-items: center;
	
			select {
				margin-right: 5px;
				padding: 10px 25px 10px 10px;
			}
		}

		*:not(.widget__total) {
			font-size: rem(14px);
		}

		.button {
			padding: 15px 15px;
			margin-left: 6px;
		}

	}

	@include mq($until: 1400) {
		&__response {
			height: auto;
			flex-direction: column-reverse;
		}

		&__ajax {
			&-floor {
				width: 100%;
				height: 54vw;
			}

			&-flat {
				width: 100%;
				min-height: 45.3vw;
			}
		}

		&__floor-title {
			left: calc(50% - 66px);
		}
	}

	@include mq($until: lg) {

		&__rotate {
			margin: 0 -50px;
		}

		&-sub {
			margin-top: 70px;
		}

		&__panel {

			padding-top: 20px;
			padding-bottom: 20px;
			align-items: flex-end;

			& > div {
				display: flex;
			}

			label {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
			}

			select {
				margin: 5px 5px 5px 0;
				padding: 10px 25px 10px 10px;
			}

			.button {
				padding: 15px;
				margin: 5px;
				width: 170px;
			}
		}

		&__house {
			top: 20px;
			width: 165px;
			height: 165px;
		}

		&__total {
			font-size: 44px;
		}
	}

	@include mq($until: md) {

		overflow: unset;
		margin-bottom: 100px;

		.rotate {
			
			top: unset;
			bottom: 25px; 

			svg {
				width: 40px;
			}
		}

		&-sub {
			margin-bottom: 150px;
		}
		
		&__panel {
			padding: 20px;
			top: 100%;
			bottom:unset;

			.button {
				width: auto;
				padding: 15px;
			}

			select {
				margin: 5px 10px 5px 0;
			}

			span {
				margin-left: 7px;
			}
		}

		&__house {
			top: 10px;
			width: 145px;
			height: 145px;
		}

		&__total {
			line-height: 1;
			font-size: 40px;
		}

		*:not(.widget__total) {
			font-size: rem(15px);
		}
	}

	@include mq($until: sm) {
		margin-bottom: 50px;

		&-sub {
			margin-bottom: 300px;
		}

		&__panel {
			flex-direction: column;
			align-items: center;
			padding: 20px 30px;

			select {
				margin: 5px;
				padding: 12px 60px 12px 25px;
			}

			&> div {
				margin: 5px 0;
			}

			.button {
				width: 145px;
			}
		}

		&__ajax {
			&-floor {
				height: 55vw;
			}
		}

	}

	@include mq($until: 700) {

		&__ajax-floor {
			height: 58vw;
		}

		&__floor-title {
			top: 20px;
		}
	}

	@include mq($until: 500) {

		.rotate {

			bottom: 20px; 

			svg {
				width: 30px;
			}
		}
	}
}

.floor-tip, .apt-tip {
	position: absolute;
	z-index: 50;
	padding: 15px;
	background-color: rgba(0, 0, 0, 0.486);
	top: 0;
	border-radius: 5px;
	opacity: 0;
	transition: opacity .5s;
	
	* {
		font-size: rem(13px);
		color: white;
	}
}

.info {
	* {
		text-align: center;
		color:rgb(49, 49, 49);
	}
}

.fl {
	fill: $color-main;
	opacity: 0;
	transition: all .5s;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}


.apt {
	fill: $color-main;
	cursor: pointer;
	transition: all .4s;
	&:hover {
		opacity: .8;
	}
}

#floorPartial, #flatPartial {
	transition: all .5s;
	height: 100%;
}

.up, .up:hover {
	background: transparent!important;
	border: none!important;

	circle {
		transition: all .4s;
	}

	&:hover {
		circle {
			fill: $color-main;
		}

		path {
			fill: white;
		}
	}
}