.text-intro {
    margin-top: 70px;
    &__heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;

        .button {
            margin-left: 25px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;

        &-right {
            width: 47%;
            padding-top: 39px;

            &>div {
                display: flex;
                justify-content: space-between;
                margin-top: 50px;
                
                p {
                    font-style: italic;
                }

                .sep {
                    width: 1px;
                    height: auto;
                    background-color: $color-main;
                }
            }
        }

        &-left {
            width: 47%;
        }
    }

    @include mq($until: 1650) {
        &__content {
            &-right {
                width: 55%;
            }
    
            &-left {
                width: 42%;
            }
        }
    }
    @include mq($until: 1636) {
		&__content-right {
			padding-top: 60px;
		}
	}

    @include mq($until: xl) {
        &__content {
            &-right {
                width: 57%;
                padding-top: 50px;
            }
    
            &-left {
                width: 40%;

                h3 {
                    height: 32px;
                }
            }
        }
    }

    @include mq($until: 1490) {

    }


    @include mq($until: lg) {
        &__content {
            flex-direction: column;
            &-right {
                width: 100%;     

                &>div {
                    justify-content: center;

                    .sep {
                        margin: 0 20px;
                    }
                }
            }

            &-left {
                width: 100%;
            }
        }

        &__heading {
            margin-bottom: 50px;
            .button {
                font-size: rem(14px);
                width: 230px;
            }
        }
    }

    @include mq($until: md) {
        &__content {
    
            &-left {

                h3 {
                    height: auto;
                }
            }

            &-right {
                > div {
                    p {
                        text-align: center;
                    }
                }

            }
        }

        .more {
            display: none;
        }
    }

    @include mq($until: xs) {
        &__heading {
            flex-direction: column;
            text-align: center;

            .button {
                    margin: 0 auto;
            }
        }   

        h1 {
            width: 100%;
        }

        &__content {

            h3 {
                margin-bottom: 10px;
            }
            
            &-right {   

                &>div {
                    flex-direction: column;
                    text-align: center;

                    .sep {
                        height: 1px;
                        width: 100%;
                        margin: 10px 0;
                    }
                }
            }
        }
    }
}