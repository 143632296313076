.leaflet-pane {
    z-index: 2!important;
}
.map {
    margin-bottom: 200px;

    #mapName {
        width: 100%;
    }

    .map {
        margin-bottom: 0;
        height: 100%;
    }

    h2 {
        text-align: center;
        color: $color-main;
        font-weight: 300;
        margin-bottom: 40px;
    }

    &> div {
        padding: 0 5vw;
        display: flex;
        justify-content: space-between;
    }

    ul {
        display: flex;
        flex-direction: column;
        height: 90%;
        justify-content: space-between;
    }

    &__marker {
        background-image: url(../../images/map-pin.png);
        width: 100px;
        height: 100px;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -54px;
        left: 51%;
    }

    &__list {
        width: 22%;
        margin-top: -60px;

        p {
            margin-bottom: 3px;
        }

        span {
            font-style: italic;
            font-size: rem(13px);
        }

        li {
            &::after {
                content: '';
                display: block;
                width: 45px;
                height: 1px;
                background-color: $color-main;
                margin-top: 7px;
            }
        }
    }

    &__container {
        height: 37vw;
        width: 44vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        border: 30px solid white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.199);
        position: relative;
        background-size: cover;
        background-position: center;

        iframe {
            filter: grayscale(1);
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border: 2px solid $color-main;
            z-index: 1;
        }
    }

    @include mq($until: 1400) {
        &__container {
            height: 50vw;
            width: 44vw;
            border: 20px solid white;
        }
    }

    @include mq($until: lg) {
        margin-bottom: 150px;
        &> div {
            position: relative;
        }

        h2 {
            margin-bottom: 80px;
        }

        &__list {
            width: 300px;
            margin-top: -60px;
            position: absolute;
            z-index: 10;

            ul {
                background-color: white;
                padding: 20px;
                box-shadow: 0px 3px 18px #00000017;
                display: none;
            }
            
            .button {
                margin: 0 auto;
                display: block;
            }

            &_right {
                right: 5vw;
            }
        }

        &__container {
            height: 70vw;
            width: 100%;
        }

    }

    @include mq($until: md) {

        margin-bottom: 80px;

        &__container {
            height: 80vw;
            min-height: 580px;
        }

        &__list {
            width: 270px;
        }

        .button {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            max-width: unset;
        }
    }

    @include mq($until: 610) {
        .button {
            width: 100%;
        }

        iframe {
            height: 182%;
            transform: scale(0.7);
            width: 250%;
        }

        &__list {
            width: 44%;

            &_right {
                ul {
                    width: 90vw;
                    margin-left: -46vw;
                }
            }
            &_left {
                ul {
                    width: 90vw;
                }
            }
        }
    }
}