
.top {
    display: flex;
    width: 100%;
    position: relative;
    height: auto;

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid $color-main;
        z-index: 1;
        content: '';
        top: -4vw;
        left: -8vw;
        pointer-events: none;
        max-height: 60vw;
    }

    
    .fb {
        svg {
            fill: $color-extra;
            height: 30px;
            
            &:hover {
                fill: $color-main; 
            }
        }
    }

    &__sidebar {
        width: 28%;
        padding-top: 40px;
        height: 100%;
        max-height: 60vw;
        min-width: 500px;
        background-color: #ffffffa6;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        transition: all .4s;

        &:hover {
            background-color: white;
        }

        h4 {
            font-size: rem(17px);
        }

        p {
            font-size: rem(14px);
            font-style: italic;
            opacity: 0;
            transition: all .4s;
            height: 0;
        }

        li {
            margin-bottom: 10px;
            transition: all .4s;
            height: 50px;

            * {
                transition: all .4s;
            }

            &::after {
                display: block;
                width: 20%;
                height: 1px;
                content: '';
                background-color: $color-main;
                margin-top: 10px;
                opacity: 0;
                
            }

            &:hover {
                &::after {
                    width: 100%;
                    opacity: 1;
                    transition: all .7s;
                }

                height: 100px;

                p {
                    opacity: 1;
                    height: auto;
                }
            }
        }
    }

    &__menu {
        padding-right: 4vw;
    }

    &__logo {
        margin-bottom: 2vw;
    }

    &__slider {
        width: 100%;
        height: 100vh;
        max-height: 60vw;

        img {
            object-fit: cover;
            object-position: center bottom;
        }

        &>div {
                height: 100%;
                max-height: 60vw;
            * {
                height: 100%;
                max-height: 60vw;
                width: 100%;
            }
        }
    }

    @include mq($until: xxl) {
        &__sidebar {
            min-width: 420px;
            min-height: 900px;
        }

        &::after {
            min-height: 900px;
        }

        &__slider {
            min-height: 900px;
            
            &>div {
                min-height: 900px;
                * {
                    min-height: 900px;
                }
            }
        }

        &__menu {
            padding-right: 2vw;
        }
    
    }

    @include mq($until: xl) {
        &__sidebar {
            min-width: 400px;
            min-height: 780px;

            li {
                margin-bottom: 20px;
            }    
        }

        &::after {
            min-height: 780px;
        }

        &__slider {
            min-height: 780px;
            
            &>div {
                min-height: 780px;
                * {
                    min-height: 780px;
                }
            }
        }

        &__menu {
            padding-right: 2vw;
        }
    
    }

    @include mq($until: lg) {
        &__logo {
            width: 100px;
            margin-bottom: 10px;
        }

        li {
            height: 20px;
        }

        &::after {
            min-height: 670px;
        }

        &__sidebar {
            min-width: 350px;
            min-height: 670px;

            h4 {
                margin-bottom: 10px;
                font-size: rem(16px);
            }
        }

        &__slider {
            min-height: 670px;
            
            &>div {
                min-height: 670px;
                * {
                    min-height: 670px;
                }
            }
        }
    }

    @include mq($until: md) {
        height: auto;
        flex-direction: column;

        &__sidebar {
            padding-top: 20px;
            flex-direction: row;
            display: flex;
            min-height: 315px;
            height: 45vh;
            max-height: unset;
            width: calc(100% - 9vw);      
            position: relative;
            background-color: white;   
            display: none; 
            
            p {
                height: auto;
                opacity: 1;
            }

            li {

                margin-bottom: 20px;
                
                &::after {
                    opacity: 1;    
                }

                &:hover {
                    &::after {
                        width: 100%;
                        opacity: 1;
                        transition: all .7s;
                    }
    
                    height: auto;
    
                    p {
                        opacity: 1;
                        height: auto;
                    }
                }
            }
        }
        
        &__slider {
            width: 100%;
            min-width: unset;
            min-height: 450px;
            height: 55vh;
            max-height: unset;

            &>div {
                min-height: 450px;
                height: 55vh;
                max-height: unset;
                * {
                    min-height: 450px;
                    height: 55vh;
                    max-height: unset;
                }
            }
        }

        &__logo {
            width: 70px;
            height: fit-content;
            margin-bottom: 5px;
            margin-right: 30px;
        }

        &__menu {

            display: block;
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            li {
                margin-top: 0;
                height: auto;
            }
        }

        &::after {
            min-height: unset;
            height: 100%;
            max-height: unset;
            opacity: 1;
        }
    }

    @include mq($until: xs) {
        height: auto;

        &::after {
            left: -15px;
            top: -15px;
        }

        &__logo {
            width: 50px;
            margin-right: 20px;
        }

        &__sidebar {
            height: 55vh;         
        }

        &__slider {
            height: 45vh;
            max-height: unset;

            &>div {
                min-height: 315px;
                height: 45vh;
                max-height: unset;
                * {
                    min-height: 315px;
                    height: 45vh;
                    max-height: unset;
                }
            }
        }

        
        @include mq($until: 500) {
            &__sidebar {
                height: auto;
                flex-direction: column;  
                width: 100%;
                text-align: center; 
                padding: 0 25px;   
                min-width: unset;   

                li {
                    &::after{ 
                        margin: 7px auto 0;
                    }
                }
            }
            &__logo {
                width: 50px;
                margin: 15px auto;
            }

            &__slider {
                height: 38vh;
                min-height: unset;

                &>div {
                    min-height: unset;
                    height: 38vh;
                    * {
                        min-height: unset;
                        height: 38vh; 
                    }
                }
            }
        }
    }
}


.slider-arrow {
	border: none;
    border-radius: 50%;
	width: 60px;
	height: 60px;
	background-size: contain;
	background-position: center;
	position: absolute;
	z-index: 2;
	transition: all .5s;
    background-color: rgba(255, 255, 255, 0.4);
    transition: all .4s;
    border: 2px solid transparent;
    backdrop-filter: blur(2px);

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border: 2px solid $color-main;
    }

	&_next {
		background-image: url(../../images/next.png);
        background-size: 30%;
        background-position: 55% center;
        background-repeat: no-repeat;
		right: calc(4vw - 30px);
        bottom: 4vw;
	}
    
    &_sm {
        right: 3vw;
        bottom: 3vw;
        width: 50px;
        height: 50px;
    }

    @include mq($until: lg) {
        width: 44px;
        height: 44px;
        &_next {
            right: calc(4vw - 22px);
        }
    }

    @include mq($until: xs) {
        &_next {
            right: 25px;
            bottom: 25px;
        }
    }
}