.article {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;

	&__text, &__image {
		width: 50%;
	}

	&__images {
		width: 45%;
		img {
			height: 35vw;
			object-fit: cover;
			width: 100%;
		}
  	}

	&__text {
		max-width: 800px;
		width: 50%;
		margin-left: 4vw;

	}

	.button {
		margin-left: auto;
		margin-top: 20px;
		min-width: 160px;
		display: block;
		width: fit-content;
	}

	@include mq($until: xl) {
		&__text {
			margin-left: 3vw;
		}
		&__images {
			img {
				min-height: 400px;
			}
		}
	}

	@include mq($until: lg) {

	}

	@include mq($until: md) {

		.slider-arrow_next {
			right: 30px;
		}

		flex-direction: column;
		margin-bottom: $padding-lg;

		&__text, &__image {
			width: 100%;
			padding: 0;
			max-width: unset;
		}

		&__text {
			margin-left: 0;
			padding-left: 50px;
		}

		&__images {
			width: 100%;
			height: 45vw;

			* {
				height: 45vw;
			}

			img {
				height: 45vw;
				min-height: unset;
			}
		}
	}

	@include mq($until: sm) {
		&__text {
			padding-left: 30px;
		}

		&__images {
			height: 60vw;

			* {
				height: 60vw;
			}

			img {
				height: 60vw;
			}
		}
	}

	@include mq($until: xs) {
		&__text {
			padding-left: 15px;
			text-align: justify;
		}
	}
}
