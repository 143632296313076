.header {
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  border-bottom: solid 2px $color-main;
  display: none;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;
  }

  &__logo{
    position: relative;
    z-index: 2;
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;
    font-size: rem(22px);
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }

  @include mq($until: md) {
    display: flex;
    z-index: 55;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    min-height: auto;
    &__logo{
      width: 50px;
    }

    &__contact {
      svg {
        width: 28px;
        margin-right: 15px;
        * {
          fill: $color-main;
        }
      }
    }
  }

  @include mq($until: sm) {
    padding-top: 10px;
    padding-bottom: 10px;
    &__logo{
      width: 35px;
    }
  }

}