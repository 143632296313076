
.about {
    position: relative;
    margin-top: 100px;

    &__element {
        display: flex;

        img {
            width: 50%;
            height: 50vh;
            object-fit: cover;
        }

        &> div {
            display: flex;
            flex-direction: column;
            align-self: center;
            width: 32%;
            min-width: 450px;
            margin: 0 auto;
            position: relative;
            left: -2vw;
        }

        &_rtl {
            flex-direction: row-reverse;
            &> div {
                left: 2vw;
            }
        }

        &:first-of-type {
            &> div {
                padding-top: 4vw;
            }
        }

        &:last-of-type {
            &> div {
                padding-bottom: 4vw;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        height: calc(100% - 8vw );
        top: 4vw;
        left: 4vw;
        width: calc(100% - 8vw );
        display: block;
        border: 2px solid $color-main;
        z-index: -1;
    }

    @include mq($until: xl) {
        margin-left: 0;
        margin-right: 0;
    }

    @include mq($until: lg) {
        margin-top: 80px;

        &__element {
            &> div {
                min-width: 300px;
                width: 38%;
            }
        }
    }

    @include mq($until: md) {
        margin-top: 50px;    
        
        &__element {
            img {
                height: 35vh;
                width: 40%;
            }

        }

        &:before {
            height: calc(100% - 4vw );
            top: 2vw;
            left: 2vw;
            width: calc(100% - 4vw );
        }

        &__element {
            min-height: 300px;
            align-items: center;
        }
    }

    @include mq($until: sm) {
        
        &__element {
            flex-direction: column;
            img {
                height: 45vw;
                width: 100%;
            }

            &> div {
                width: 80%;
                margin: 0!important;
                padding: 30px 0px!important;
                position: static;
            }

        }

        &:before {
            height: calc(100% + 4vw );
            top: calc(-15px + -2vw);
            left: 2vw;
            width: calc(100% - 4vw );
        }
    }

    @include mq($until: xs) {
        &__element {
            img {
                height: 59vw;
            }

            &> div {
                width: calc(100% - 50px);
                min-width: unset;

                h3 {
                    text-align: center;
                }

                p {
                    text-align: justify;
                }
            }
        }
    }
}