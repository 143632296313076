.button {

  color: $color-main;
  padding: 17px 25px;
  background-color: white;
  display: inline-block;
  line-height: 1;
  text-align: center;
  box-shadow: 0px 3px 18px #00000017;
  align-self: flex-start;
  cursor: pointer;
  font-size: rem(15px);
  transition: all .4s;

  &-map {
    width: 100%;
    text-align: left;
    max-width: 275px;
    padding: 10px 20px;
    pointer-events: none;

    svg {
      height: 30px;
      width: auto;
      margin-right: 15px;
    }
  }

  &:hover {
    box-shadow: 0 0 18px #00000031;
  }

  
  &-xs {
    padding: 5px 10px;
    box-shadow: none;
    border: 1px solid $color-main;

    &:hover {
      box-shadow: 0px 3px 12px #00000017;
    }
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }

  &-more {
    border: none;
    margin: 0 auto;
    outline: none;
    text-align: center;
    display: none;
    width: fit-content;

    @include mq($until: md) {
      display: block;
    }
  }

  @include mq($until: lg) {
    &-map {
      pointer-events: all;
    }
  }
}