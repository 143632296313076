.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  :focus{
    outline: none;
  }

  .button {
    width: 25%;
    max-width: 220px;
    min-width: 190px;
    background-color: #595F64;
    opacity: .4;
    transition: all .4s;
    border-color: #595F64;
    
    &:hover {
      opacity: 1;
      color: white;
    }
  }

  &__top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 35px;
  }

  .submitSection {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__row {
    position: relative;
  }

  &__input {
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #505052;
    background-color: transparent;
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    &--checkbox{
      margin: 0 5px 0 0 ;
    }
    &--textarea{
      margin-bottom: -7px;
    }

    & ~ .form__border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-main;
      transition: 0.3s;
    }

    &:focus ~ .form__border {
      width: 100%;
      transition: 0.3s;
    }

    &:focus ~ .form__label {
      top: -10px;
      font-size: 14px;
      color: $color-main;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }
  }

  &__label  {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0px;
    color: #505052;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
  }

  &__rodo {
    font-size: rem(14px);
    width: 100%;
    display: flex;
    align-items: center;
    width: 70%;
    label, input{
      cursor: pointer;
    }

    input {
      display: none;

      &:checked + label .fakebox {
        background-color: #6E6E70;
      }
    }

    label {
      display: flex;
    }

    span {
      &:not(.fakebox) {
        font-size: rem(12px);
        line-height: 1.2;
        margin-right: 20px;
      }
    }
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }

  @include mq($until: sm) {
    &__top {
      gap: 15px;
    }
  }

  @include mq($until: xs) {
    &__top {
      grid-template-columns: repeat(1, 1fr);
    }

    &__rodo {
      width: 100%;
      margin-bottom: 15px;

      @include mq($until: xs) {
        label {
          justify-content: space-between;
        }
      }
    }

    .submitSection {
      flex-direction: column;
    }
  }
}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert{
  h4{
    margin-top: 15px;
    font-size: rem(20px);
  }
}

.fakebox {
  width: 43px;
  height: 43px;
  border: 1px solid #C1BBA9;
  display: block;
  transition: all .3s;
  border-radius: 6px;
  margin-right: 10px;

  @include mq($until: sm) {
    width: 39px;
    height: 39px;
  }
}

.fakebox + span {
  width: 80%;

  @include mq($until: xs) {
    width: 80%;
  }
}