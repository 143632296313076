.flat-list {
  margin-bottom: 150px;

  @include mq($until: sm) {
    margin-top: 40px;
  }

  .rezerwacja {
    font-weight: 600;
  }

  &__wrapper {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    ul {
      margin-bottom: 0;
    }
  }

  &__overflow {

    background-color: white;
    position: relative;
    margin-top: 30px;

    li {margin: 0;}

    ul {
      overflow: auto;
      height: 600px;
      max-height: 600px;
      padding-bottom: 100px
    }
  
    &::after {
      height: 200px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0);
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
      position: absolute;
      bottom: 0;
      content: '';
      pointer-events: none;
    }
    
  }

  &__head {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: stretch;
    align-items: center;
    padding: 20px;
    margin-bottom: 5px;
    box-shadow: 0px 3px 13px #00000012;

    li {
      text-align: center;
      margin-top: 0;
    }

    select {
      padding: 5px 10px;
      outline: none;
      border: none;
      border-bottom: 1px solid $color-main;
      -webkit-appearance: none;
    }

    @include mq($until: lg) {
    }

    @include mq($until: md) {
    }

    @include mq($until: sm) {
      padding: 15px;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      margin-bottom: 20px!important;
    }
    @include mq($until: 500) {
      padding: 20px 15px;
      grid-template-columns: repeat(2, 1fr);
    }
    position: relative;

  }

  &__head-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fw-medium;
    font-size: rem(16px);
    color: #6C4F2D;
    margin-bottom: 7px;
    &--action{
      cursor: pointer;
      transition: color .3s;
      &:hover{
        color: $color-extra
      }
    }
    &.active{
      color: $color-extra
    }
    i{
      margin-right: 5px;
      padding-bottom: 9px;
    }
    &[data-order="asc"]{
      i{
        transform: scale(1);
      }
    }
    &[data-order="desc"]{
      i{
        transform: scale(-1);
      }    
    }
    @include mq($until: xxl) {
      font-size: rem(16px);
    }

    @include mq($until: xl) {
      font-size: rem(14px);
    }

    @include mq($until: md) {
      font-size: rem(15px);
    }
    
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__item {
    display: grid;
    padding: 0 10px;
    grid-template-columns: repeat(6, 1fr);
    justify-content: stretch;
    align-items: center;
    transition: 0.3s;
    position: relative;
    margin: 0;
    background-color: white;
    transition: all .3s;
    border-bottom: 1px solid $color-main;

    svg {
      width: 30px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #fbfbfb;
    }

    @include mq($until: lg) {
      
    }
    @include mq($until: md) {
      
    }

    @include mq($until: sm) {

      border: 1px solid $color-main;
      display: flex;
      flex-direction: column;

      grid-gap: 0;
      padding: 20px;
    }

    @include mq($until: sm) {
      flex-direction:column;
      align-items: center;
    }

  }
  &__status {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 5px;
  }
  &__info {
    display: flex;
    padding: 15px 0;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    font-weight: 500;
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      margin-top: 0!important;

    }
    @include mq($until: sm) {
      justify-content: flex-start;
      font-weight: $fw-bold;
      font-size: rem(18px);
      padding: 0;
      flex-basis: 100%;
      margin: 8px 0;
      &:last-child{
        margin-left: auto;

        @include mq($until: sm) {
         margin-left: unset;
        }
      }
    }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    &--button{
      @include mq($until: md) {
        flex-basis: 48%;
        .flat-list__item-label{
          display: none;
        }
        .flat-list__item-value{
          width: 100%;
          button, a, span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0;
          }
          
        }
      }
    }

  }
  &__item-value{
    position: relative;
    @include mq($until: md) {
      span {
        font-weight: 600!important;
      }
    }
  }

  @include mq($until: md) {
    &__item-desktop {
      display: none;
    } 

    margin-bottom: 100px;
  }

  &__item-label {
    display: none;
    @include mq($until: sm) {
      display: block;
      margin-right: 10px;
      font-weight: $fw-regular;
      font-size: rem(16px);
      display: inline;
    }
  }
  &__item-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      display: inline;
    }
    span:first-letter {
      text-transform: uppercase;
    }
    span,
    a {
      font-weight: 400;
      margin-left: 5px;
      color: #3F3F3F;
      transition: all .3s;
      @include mq($until: xxl) {
        font-size: rem(14px);
      }
      @include mq($until: xl) {
        font-size: rem(13px);
      }
      @include mq($until: md) {
        font-size: rem(14px);
      }
      @include mq($until: sm) {
        font-size: rem(16px);
      }
    }
    svg {
      height: 25px;
      fill: $color-main;
      transition: 0.2s;
      margin-right: 5px;
      @include mq($until: xl) {
        height: 21px;
      }
    }
    a,
    button {
      border: 1px solid #000;
      background-color: #fff;
      padding: 5px 10px;
      transition: 0.2s;

      &:hover {
        border: 1px solid $color-main;
        background-color: $color-main;
        color: $color-font-light;
        svg {
          fill: $color-font-light;
          @include mq($until: md) {
            // width: 10px
          }
        }
      }
      @include mq($until: md) {
        padding: 5px 8px;
        font-size: rem(16px);
        font-weight: $fw-semi-bold;
      }
    }
  }

  &__no-results {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: rem(26px);
    color: $border-color;
  }

  @include mq($until: md) {

    &__item {
      padding: 15px;
    }

  }

  @include mq($until: sm) {

    &__wrapper {
      margin-top: 0;
    }

    &__item {
      &> div {
        max-width: 310px;
        width: 100%;
        justify-content: space-between;
      }
    }

    &__overflow {
      margin-top: 0;
      height: 65vh;
      ul {
        margin-top: 0;
        height: 65vh;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }
    }
  }

  @include mq($until: 515) {
    &__overflow {
      ul {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

}
.pdf-butons{
  display: flex;
  justify-content: center;
  @include mq($until: md) {
    a{
      margin-bottom: 5px;
    }
    
    div {
      margin-bottom: 0;
      justify-content: flex-end;
      font-size: rem(20px);
    }
  }

  &__separator{
    padding: 0 5px;
    opacity: .3;
    @include mq($until: md) {
      padding: 0 13px;
    }
  }
}

.flatslist-wrapper {
  position: relative;
  margin-top: 30px;

  @include mq($until: sm) {
    padding: 0;
  }
}

#loading, #loading-list, #loading-floor, #loading-flat {
  border: 16px solid #ffffff65;
  border-radius: 50%;
  border-top: 16px solid $color-main;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  backdrop-filter: blur(3px);
  display: none;
}

#loading-list, #loading-floor, #loading-flat {
  left: calc(50% - 88px);
  top: 50px;

  @include mq($until: lg) {
    left: calc(50% - 56px);
    top: 185px;
  }
}

#loading-floor, #loading-flat {
  top: calc(50% - 88px);
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}